import 'semantic-ui-css/semantic.min.css';

import CreateCampaign from './campaign/Create/create.campaign';
import usePageData from './data.hook';
import PreviewModal from './preview/PreivewModal';
import useSearchAndWheel from './wheel.data.hook';

function App() {
  const { methods, ...values } = usePageData()
  const { methods: wheelMethods, ...wheelValues } = useSearchAndWheel()
  return (
    <>
      <CreateCampaign
        campaignNegativeKeywords={values.campaignNegativeKeywords}
        pushToCombinations={methods.pushToCombinations}
        rightRef={values.rightRef}
        leftRef={values.leftRef}
        rootRef={values.rootRef}
        roots={values.roots}
        leftCombinations={values.leftCombinations}
        rightCombinations={values.rightCombinations}
        removeCombination={methods.removeCombination}
        setShowPreivewModal={methods.openPreview}
        sliderValue={values.sliderValue}
        setSliderValue={methods.setSliderValue}
        pushToNegativeCampaign={methods.pushToNegativeCampaign}
        combinationFactors={values.combinationFactors}
        setCombinationFactorMethod={methods.setCombinationFactorMethod}
        removeFromNegativeCampaign={methods.removeFromNegativeCampaign}
        setNegativeKeywordFactorsMethod={methods.setNegativeKeywordFactorsMethod}
        negativeKeywordFactors={values.negativeKeywordFactors}
        keywordCounts={values.keywordCounts}
        wheelMethods={wheelMethods}
        wheelValues={wheelValues}
      />
      <PreviewModal
        campaignNegativeKeywords={values.campaignNegativeKeywords}
        roots={values.roots}
        leftCombinations={values.leftCombinations}
        rightCombinations={values.rightCombinations}
        open={values.showPreviewModal}
        toggle={methods.openPreview}
        combinationFactors={values.combinationFactors}
        negativeKeywordFactors={values.negativeKeywordFactors}
        exportCsv={methods.exportCsv}
        campaignInputRef={values.campaignInputRef}
      />
    </>
  )
}

export default App;
