import { Icon, Popup } from "semantic-ui-react"

const CombinationInfoPopup = () => {
  return (
    <Popup
    style={{textAlign: 'center'}}
      position="top center"
      trigger={<Icon circular name='info' size='small' link />}
      content={<span>
        On the left, select how you would like to concatenate your keywords. For example, the first option concatenates the “Left” combinations with the “Roots / Ad Groups”.<br /><br/>

        On the right, select the keyword match types.
      </span>}
    />
  )
}

export default CombinationInfoPopup