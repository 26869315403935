import React from 'react'
import { Grid, Icon, Input, Label, LabelGroup } from 'semantic-ui-react'

const Combination = (props) => {
  const {
    title,
    items,
    placeholder,
    elementRef,
    pushToCombinations,
    removeCombination,
    id,
  } = props

  return <>
    <Grid>
      <Grid.Row stretched>
        <Grid.Column width='16' textAlign='center'>
          <p className='combination-header'>{title} <span>{items.length}</span></p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width='16'>
          <div className='combination-items-container'>
            <LabelGroup>
              {
                items.map((item, i) => (
                  <Label size='small' key={item + i} as='a' style={{ backgroundColor: '#B8E0D2' }} className='default-responsive-font-size'>
                    {item}
                    <Icon name='delete' onClick={() => removeCombination(item, id)} />
                  </Label>
                ))
              }
            </LabelGroup>
          </div>
        </Grid.Column>
        <Grid.Column width='16'>
          <form onSubmit={(e) => {
            e.preventDefault()
            pushToCombinations(id)
          }}>

            <Input
              size='mini'
              focus={true}
              name='keyword'
              title={placeholder}
              className='combination-input'
              icon={{
                name: 'arrow circle up',
                color: 'blue',
                size: 'large',
                link: true,
                onClick: () => pushToCombinations(id)
              }}
              ref={elementRef}
              fluid
              placeholder={placeholder}
            />
          </form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}

export default Combination
