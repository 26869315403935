import { Breadcrumb, Label } from "semantic-ui-react";
import './healthWheel.style.css';

const HealthWheel = (props) => {
  const { wheelMethods: methods, wheelValues: data, sliderValue } = props
  const generateWheel = () => {
    return (
      <div className='wheel-case'>
        <Label className='root' style={{ backgroundColor: '#F3F0D7' }}>
          {
            data?.current?.name ?
              <>{data.current.name}</>
              : ''}
        </Label>
        {
          data?.current?.children ?
            data?.current?.children.map((child, i) => (

              ((i + 1) <= sliderValue) && <div
                key={child.name + i}
                className={
                  `line ${((i + 1) % 2 === 0) ?
                    'line2' :
                    ((i + 1) % 3 === 0) ?
                      'line3' :
                      ''
                  } ${child.name.length >= 35 && 'line3'}`
                }
                style={{
                  '--rotateX': `${(i) * (360 / (data.current.children.length <= sliderValue ? data.current.children.length : sliderValue))}deg`,
                  transform: `rotate(var(--rotateX))`,
                  transformOrigin: 'top left',
                }}
              >
                <Label
                  size='medium'
                  onClick={() => child.totalChildren && methods.changeCurrent(child.path)}
                  className={`
                  line-content 
                  ${child.totalChildren && 'button-shadow'} 
                  ${methods.isViewed(`${child.path}`) ? 'viewedNodes' : data.current.isRoot ? 'unviewedNodes' : 'viewedNodesNotRoot'}
                  ${!child.totalChildren && 'lastNode'}
                  `}
                  style={{
                    '--rotateXLine': `-${(i) * (360 / (data.current.children.length <= sliderValue ? data.current.children.length : sliderValue))}deg`,
                    transform: `rotate(var(--rotateXLine)) translate(50%, -50%)`,
                    transformOrigin: `top right`,
                    // backgroundColor: methods.isViewed(child.path) ? '#D6D6D6 !important' : '#EEDDD3 !important'
                  }}
                >
                  {/* {methods.isViewed(`${child.path}`) ? <Icon name='checkmark' color='black' /> : <Icon name='eye' color='black' />}  */}
                  {child.name}
                </Label>
              </div>
            )) : null
        }
      </div>
    )
  }

  return (
    <div className='section2'>
      <div className='breadCrumbs'></div>
      <div className='wheel-wrapper'>
        <Breadcrumb>
          {
            data.breadCrumbs && data.breadCrumbs.map(bc => (
              <span key={bc.key}>
                <Breadcrumb.Section className="home-icon" onClick={() => methods.jumpToSection(bc.key)}>
                  {/* <Label> */}
                  <span style={{ fontSize: '0.9rem' }}>{bc.content}</span>
                  {/* </Label> */}
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right angle' style={{ fontSize: '1.2rem!important' }} />
              </span>
            ))
          }
        </Breadcrumb>
      </div>

      {generateWheel()}

    </div>
  )
}

export default HealthWheel;