import React from 'react'
import { Grid, Loader, Modal, Segment } from 'semantic-ui-react'
import HealthWheel from '../../healthWheel/HealthWheel'
import CombinationSection from './components/CombinationSection'
import CampaignSearch from './components/search'

const CreateCampaign = (props) => {
  const { setNegativeKeywordFactorsMethod, removeFromNegativeCampaign, setCombinationFactorMethod, pushToNegativeCampaign, pushToCombinations, removeCombination, setShowPreivewModal, setSliderValue, ...values } = props
  return (
    <>
      <Grid className='wrapper' stretched>
        <Grid.Row>
          <Grid.Column width={10}>
            <Segment style={{ flexGrow: 'unset' }}>
              <CampaignSearch
                sliderValue={values.sliderValue}
                setSliderValue={setSliderValue}
                wheelMethods={props.wheelMethods}
                wheelValues={props.wheelValues}
              />
            </Segment>
            <Segment>
              {
                !props.wheelValues.isFetching && <HealthWheel
                  wheelMethods={props.wheelMethods}
                  wheelValues={props.wheelValues}
                  sliderValue={values.sliderValue}
                />
              }
              <Modal open={props.wheelValues.isFetching} size='fullscreen' closeOnDimmerClick={false}>
                <Loader size='massive' />
              </Modal>
            </Segment>

          </Grid.Column>
          <Grid.Column width={6} className='right-side' verticalAlign='middle'>
            <CombinationSection
              campaignNegativeKeywords={values.campaignNegativeKeywords}
              pushToCombinations={pushToCombinations}
              rightRef={values.rightRef}
              leftRef={values.leftRef}
              rootRef={values.rootRef}
              roots={values.roots}
              leftCombinations={values.leftCombinations}
              rightCombinations={values.rightCombinations}
              removeCombination={removeCombination}
              setShowPreivewModal={setShowPreivewModal}
              pushToNegativeCampaign={pushToNegativeCampaign}
              setCombinationFactorMethod={setCombinationFactorMethod}
              combinationFactors={values.combinationFactors}
              removeFromNegativeCampaign={removeFromNegativeCampaign}
              setNegativeKeywordFactorsMethod={setNegativeKeywordFactorsMethod}
              negativeKeywordFactors={values.negativeKeywordFactors}
              keywordCounts={values.keywordCounts}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default CreateCampaign