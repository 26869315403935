import { Button, Grid, Header, Icon, Input, Select } from "semantic-ui-react"
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

const countries = [
  { key: 'all', text: 'All', value: 'all' },
  { key: 'us', text: 'U.S', value: 'us' },
  { key: 'uk', text: 'U.K', value: 'uk' },
]

const languages = [
  { key: 'select', text: 'Select', value: 'select' },
  { key: 'english', text: 'English', value: 'english' },
]

const CampaignSearch = (props) => {
  const { sliderValue, wheelMethods: methods, wheelValues: values } = props
  return <div className='campaign-search white-background' style={{ flexGrow: 0 }}>
    <p className="search-heading">Search keywords. What's your campaign about?</p>
    <form onSubmit={(e) => {
      e.preventDefault()
      methods.getGraphKeywordsMethod()
    }}>
      <Input className="search-box" size='big' type='text' placeholder='Search...' action fluid icon iconPosition='left' onChange={(e, data) => { methods.setSearchKeyword(data.value) }}>
        <Icon name='search' className="default-responsive-font-size-search" />
        <input className="default-responsive-font-size-search" />
        <Select name='country' compact options={values?.countries ?? countries} onChange={(e, { value }) => methods.onSearchDropdownChange(value, 'country')} defaultValue='United States' className="default-responsive-font-size-search" />
        <Select name='language' compact options={values?.languages ?? languages} onChange={(e, { value }) => methods.onSearchDropdownChange(value, 'language')} defaultValue='English' className="default-responsive-font-size-search" />
        <Button type='submit' color='linkedin' className="default-responsive-font-size-search">Search</Button>
      </Input>
    </form>

    <Grid padded >
      <Grid.Row>
        <Grid.Column width={6}>
          <div className="search-heading2">Keywords Nodes</div>
        </Grid.Column>
        <Grid.Column floated='right'>
          <Header as='h2' color='blue'>
            {sliderValue}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <div className="slider-horizontal">
      <Slider
        min={1}
        max={values.maxNodesLimit}
        value={sliderValue}
        onChange={(value) => {
          props.setSliderValue(value)
        }}
        tooltip={false}
      />
    </div>
  </div>
}

export default CampaignSearch