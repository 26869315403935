import { Icon, Popup } from "semantic-ui-react"

const NegativeCampaignInfoPopup = () => {
  return (
    <Popup
    style={{textAlign: 'center'}}
    position="top center"
      trigger={<Icon circular name='info' size='small' link />}
      content={<span>
        On the left, when the “Keyword sculpting” option is selected, the roots from every other ad group are automatically excluded. In this way, ad groups do not compete with each other.<br /> <br />

        On the right, select the negative keywords match types.
      </span>}
    />
  )
}

export default NegativeCampaignInfoPopup