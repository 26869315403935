import React from 'react'
import { Modal } from 'semantic-ui-react'
import Preview from './Preview'
import './style.css'

const PreviewModal = (props) => {
  const { open, exportCsv, toggle, combinationFactors, negativeKeywordFactors, campaignNegativeKeywords, roots, leftCombinations, rightCombinations, campaignInputRef } = props
  return (
    <Modal className='preview-modal' open={open} onClose={() => toggle(false)} onOpen={() => toggle(true)} size='large'>
      <Modal.Content>
        <Preview
          campaignNegativeKeywords={campaignNegativeKeywords}
          roots={roots}
          leftCombinations={leftCombinations}
          rightCombinations={rightCombinations}
          combinationFactors={combinationFactors}
          negativeKeywordFactors={negativeKeywordFactors}
          exportCsv={exportCsv}
          campaignInputRef={campaignInputRef}
        />
      </Modal.Content>
    </Modal>
  )
}

export default PreviewModal