import React from 'react'
import { Table, TableCell } from 'semantic-ui-react'

const PreviewTable = (props) => {
  const { combinationFactors, leftCombinations, rightCombinations, roots, negativeKeywordFactors } = props
  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Ad Group
            </Table.HeaderCell>
            <Table.HeaderCell>
              Keywords
            </Table.HeaderCell>
            <Table.HeaderCell>
              Negative Keywords
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className='preview-table-body'>
          {
            roots.map((root, i) => (
              <Table.Row key={root + i}>
                <TableCell>{root}</TableCell>
                <TableCell>
                  <div className='preview-table-cell'>

                    {
                      combinationFactors.dot1 && leftCombinations.map((lc, j) => (
                        <div key={root + i + j}>
                          {combinationFactors.broad && <div>{lc} {root}</div>}
                          {combinationFactors.quotes && <div>"{lc} {root}"</div>}
                          {combinationFactors.brackets && <div>[{lc} {root}]</div>}
                        </div>
                      ))
                    }

                    {
                      combinationFactors.dot2 && <div key={i}>
                        {combinationFactors.broad && <div>{root}</div>}
                        {combinationFactors.quotes && <div>"{root}"</div>}
                        {combinationFactors.brackets && <div>[{root}]</div>}
                      </div>
                    }
                    {
                      combinationFactors.dot3 && rightCombinations.map((rc, j) => (
                        <div key={i + j}>
                          {combinationFactors.broad && <div>{root} {rc} </div>}
                          {combinationFactors.quotes && <div>"{root} {rc}"</div>}
                          {combinationFactors.brackets && <div>[{root} {rc}]</div>}
                        </div>
                      ))
                    }
                    {
                      combinationFactors.dot4 && leftCombinations.map((lc, j) => (
                        rightCombinations.map((rc, k) => (
                          <div key={i + j + k}>
                            {combinationFactors.broad && <div>{lc} {root} {rc} </div>}
                            {combinationFactors.quotes && <div>"{lc} {root} {rc}"</div>}
                            {combinationFactors.brackets && <div>[{lc} {root} {rc}]</div>}
                          </div>
                        ))
                      ))
                    }
                  </div>
                </TableCell>
                <TableCell>
                  <div className='preview-table-cell'>
                    {
                      negativeKeywordFactors.keyboardSculpting && roots.map(r => r !== root ? <div key={r}>
                        {negativeKeywordFactors.broad && <div>{r}</div>}
                        {negativeKeywordFactors.quotes && <div>"{r}"</div>}
                        {negativeKeywordFactors.brackets && <div>[{r}]</div>}
                      </div> : false)
                    }
                  </div>
                </TableCell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </>
  )
}

export default PreviewTable