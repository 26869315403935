import { useRef, useState, useEffect } from "react"
import * as xlsx from 'xlsx'

const usePageData = () => {
  const [campaignNegativeKeywords, setCampaignNegativeKeywords] = useState([])
  const [roots, setRoots] = useState([])
  const [leftCombinations, setLeftCombinations] = useState([])
  const [rightCombinations, setRightCombinations] = useState([])
  const [showPreviewModal, setShowPreivewModal] = useState(false)
  const [sliderValue, setSliderValue] = useState(10)
  const [combinationFactors, setCombinationFactors] = useState({
    broad: false,
    quotes: false,
    brackets: false,
    dot1: false,
    dot2: false,
    dot3: false,
    dot4: false,
  })

  const [negativeKeywordFactors, setNegativeKeywordFactors] = useState({
    broad: false,
    quotes: false,
    brackets: false,
    keyboardSculpting: false,
  })

  const [keywordCounts, setKeywordCounts] = useState({
    combinationCount: 0,
    negativeKeywordCount: 0,
  })

  const rootRef = useRef()
  const leftRef = useRef()
  const rightRef = useRef()
  const campaignInputRef = useRef()

  useEffect(() => {
    let combinationCount = 0
    if (combinationFactors.dot1) {
      combinationCount += leftCombinations.length * roots.length
    }
    if (combinationFactors.dot2) {
      combinationCount += roots.length
    }
    if (combinationFactors.dot3) {
      combinationCount += roots.length * rightCombinations.length
    }
    if (combinationFactors.dot4) {
      combinationCount += leftCombinations.length * roots.length * rightCombinations.length
    }
    if (combinationFactors.broad && combinationFactors.quotes && combinationFactors.brackets)
      combinationCount *= 3
    else if ((combinationFactors.broad && combinationFactors.quotes) || (combinationFactors.broad && combinationFactors.brackets) || (combinationFactors.quotes && combinationFactors.brackets)) {
      combinationCount *= 2
    }

    setKeywordCounts(k => ({ ...k, combinationCount }))

  }, [combinationFactors.dot1, combinationFactors.dot2, combinationFactors.dot3, combinationFactors.dot4, combinationFactors.broad, combinationFactors.brackets, combinationFactors.quotes, leftCombinations, roots, rightCombinations, setKeywordCounts])

  useEffect(() => {
    let negativeKeywordCount = 0


    if (
      (negativeKeywordFactors.broad &&
      negativeKeywordFactors.brackets &&
      negativeKeywordFactors.quotes)
    )
      negativeKeywordCount = ((campaignNegativeKeywords.length ?? 0) * 3) + (negativeKeywordFactors.keyboardSculpting ? roots.length * (roots.length - 1) * 3 : 0)
    else if (
      ((negativeKeywordFactors.broad && negativeKeywordFactors.quotes) || (negativeKeywordFactors.broad && negativeKeywordFactors.brackets) || (negativeKeywordFactors.quotes && negativeKeywordFactors.brackets))
    )
      negativeKeywordCount = ((campaignNegativeKeywords.length ?? 0) * 2) + (negativeKeywordFactors.keyboardSculpting ? (roots.length * (roots.length - 1) * 2) : 0)
    else if (
      (negativeKeywordFactors.broad || negativeKeywordFactors.quotes || negativeKeywordFactors.brackets)
    )
      negativeKeywordCount = (campaignNegativeKeywords.length ?? 0) + (negativeKeywordFactors.keyboardSculpting ? (roots.length * (roots.length - 1)) : 0)

    setKeywordCounts(keywordCounts => ({
      ...keywordCounts,
      negativeKeywordCount,
    }))

  }, [negativeKeywordFactors, campaignNegativeKeywords, roots.length])

  const pushToCombinations = (type) => {
    let value;
    switch (type) {
      case 'root':
        value = rootRef.current.inputRef.current.value;
        if (value && !roots.includes(value.toLowerCase())) {
          setRoots([...roots, value.toLowerCase()])
          rootRef.current.inputRef.current.value = ''
        }
        break;
      case 'left':
        value = leftRef.current.inputRef.current.value;
        if (value && !leftCombinations.includes(value.toLowerCase())) {
          setLeftCombinations([...leftCombinations, value.toLowerCase()])
          leftRef.current.inputRef.current.value = ''

        }
        break;
      case 'right':
        value = rightRef.current.inputRef.current.value;
        if (value && !rightCombinations.includes(value.toLowerCase())) {
          setRightCombinations([...rightCombinations, value.toLowerCase()])
          rightRef.current.inputRef.current.value = ''
        }
        break;
      default:
        break;
    }
  }

  const removeCombination = (value, type) => {
    let values;
    switch (type) {
      case 'root':
        values = roots.filter(r => r.toLowerCase() !== value.toLowerCase())
        setRoots([...values])
        break;
      case 'left':
        values = leftCombinations.filter(r => r.toLowerCase() !== value.toLowerCase())
        setLeftCombinations([...values])
        break;
      case 'right':
        values = rightCombinations.filter(r => r.toLowerCase() !== value.toLowerCase())
        setRightCombinations([...values])
        break;
      default:
        break;
    }
  }

  const setCombinationFactorMethod = (key) => {
    setCombinationFactors({
      ...combinationFactors,
      [key]: !combinationFactors[key]
    })

  }

  const pushToNegativeCampaign = (value) => {
    if (value && !campaignNegativeKeywords.includes(value)) {
      setCampaignNegativeKeywords([...campaignNegativeKeywords, value])
    }
  }

  const removeFromNegativeCampaign = (value) => {
    setCampaignNegativeKeywords([...campaignNegativeKeywords.filter((cnk) => cnk !== value)])
  }

  const setNegativeKeywordFactorsMethod = (key) => {
    setNegativeKeywordFactors({
      ...negativeKeywordFactors,
      [key]: !negativeKeywordFactors[key]
    })
  }

  const openPreview = (open) => {
    if (open) {
      if (!combinationFactors.dot1 && !combinationFactors.dot2 && !combinationFactors.dot3 && !combinationFactors.dot4) {
        alert('Make sure you have selected at least one keyword combination')
        return
      }
      if (!combinationFactors.brackets && !combinationFactors.broad && !combinationFactors.quotes) {
        alert('Select at least one match type between broad, phrase and exact match')
        return
      }
      if (campaignNegativeKeywords.length)
        if (!negativeKeywordFactors.quotes && !negativeKeywordFactors.broad && !negativeKeywordFactors.brackets) {
          alert('Select at least one match type between broad, phrase and exact match')
          return
        }
      if ((combinationFactors.dot1 && (!leftCombinations.length || !roots.length)) ||
        (combinationFactors.dot2 && !roots.length) ||
        (combinationFactors.dot3 && (!roots.length || !rightCombinations.length)) ||
        (combinationFactors.dot4 && (!roots.length || !rightCombinations.length || !leftCombinations))
      ) {
        alert('There are no keywords added')
        return
      }
    }
    setShowPreivewModal(open)
  }

  const exportCsv = () => {
    const campaignInputValue = campaignInputRef.current.inputRef.current.value
    if (!campaignInputValue)
      return alert('You need to add a campaign name at the top before downloading your keywords.')
    const wb = xlsx.utils.book_new()
    const ws_name = 'Campaign Ad Groups'
    const ws_name2 = 'Campaign Negative Keywords'
    let sheetData = [['Campaign', 'Ad Group', 'Keyword']]

    roots.forEach((root, i) => {
      if (combinationFactors.dot1) {
        leftCombinations.forEach((lc, j) => {
          if (combinationFactors.broad) {
            sheetData.push([campaignInputValue, root, `${lc} ${root}`])
          }
          if (combinationFactors.quotes) {
            sheetData.push([campaignInputValue, root, `"${lc} ${root}"`])
          }
          if (combinationFactors.brackets) {
            sheetData.push([campaignInputValue, root, `[${lc} ${root}]`])
          }
        })
      }
      if (combinationFactors.dot2) {
        if (combinationFactors.broad)
          sheetData.push([campaignInputValue, root, `${root}`])
        if (combinationFactors.quotes)
          sheetData.push([campaignInputValue, root, `"${root}"`])
        if (combinationFactors.brackets)
          sheetData.push([campaignInputValue, root, `[${root}]`])
      }
      if (combinationFactors.dot3) {
        rightCombinations.forEach((rc, j) => {
          if (combinationFactors.broad) {
            sheetData.push([campaignInputValue, root, `${root} ${rc}`])
          }
          if (combinationFactors.quotes) {
            sheetData.push([campaignInputValue, root, `"${root} ${rc}"`])
          }
          if (combinationFactors.brackets) {
            sheetData.push([campaignInputValue, root, `[${root} ${rc}]`])
          }
        })
      }
      if (combinationFactors.dot4) {
        leftCombinations.forEach(lc => {
          rightCombinations.forEach(rc => {
            if (combinationFactors.broad)
              sheetData.push([campaignInputValue, root, `${lc} ${root} ${rc}`])
            if (combinationFactors.brackets)
              sheetData.push([campaignInputValue, root, `[${lc} ${root} ${rc}]`])
            if (combinationFactors.quotes)
              sheetData.push([campaignInputValue, root, `"${lc} ${root} ${rc}"`])
          })
        })
      }
    })
    const negativeCampaignKeywordsSheet = [['Campaign', 'Ad Group', 'Keyword', 'Criterion Type']]
    if (campaignNegativeKeywords.length) {
      campaignNegativeKeywords.forEach(cnk => {
        if (negativeKeywordFactors.broad) {
          negativeCampaignKeywordsSheet.push([campaignInputValue, ' ', cnk, 'Campaign Negative Broad'])
        }
        if (negativeKeywordFactors.brackets) {
          negativeCampaignKeywordsSheet.push([campaignInputValue, ' ', cnk, 'Campaign Negative Phrase'])
        }
        if (negativeKeywordFactors.quotes) {
          negativeCampaignKeywordsSheet.push([campaignInputValue, ' ', cnk, 'Campaign Negative Exact'])
        }
      })
      if (negativeKeywordFactors.keyboardSculpting) {
        roots.forEach((r) => {
          roots.forEach(cnk => {
            if (cnk !== r) {
              if (negativeKeywordFactors.broad) {
                negativeCampaignKeywordsSheet.push([campaignInputValue, r, cnk, 'Negative Broad'])
              }
              if (negativeKeywordFactors.brackets) {
                negativeCampaignKeywordsSheet.push([campaignInputValue, r, cnk, 'Negative Phrase'])
              }
              if (negativeKeywordFactors.quotes) {
                negativeCampaignKeywordsSheet.push([campaignInputValue, r, cnk, 'Negative Exact'])
              }
            }
          })
        })
      }
      /* negativeKeywordFactors.keyboardSculpting? r: '', */
    }

    const ws = xlsx.utils.aoa_to_sheet(sheetData)
    const ws2 = xlsx.utils.aoa_to_sheet(negativeCampaignKeywordsSheet)
    xlsx.utils.book_append_sheet(wb, ws, ws_name)
    xlsx.utils.book_append_sheet(wb, ws2, ws_name2)
    xlsx.writeFile(wb, campaignInputValue + '.xlsx')

  }

  return {
    campaignNegativeKeywords,
    leftCombinations,
    rightCombinations,
    roots,
    rightRef,
    leftRef,
    rootRef,
    showPreviewModal,
    sliderValue,
    combinationFactors,
    negativeKeywordFactors,
    keywordCounts,
    campaignInputRef,
    methods: {
      setShowPreivewModal,
      pushToNegativeCampaign,
      setSliderValue,
      pushToCombinations,
      removeCombination,
      removeFromNegativeCampaign,
      setCombinationFactorMethod,
      setNegativeKeywordFactorsMethod,
      openPreview,
      exportCsv,
    }
  }
}

export default usePageData
