import React from 'react'
import { Button, Divider, Grid, Input, Segment } from 'semantic-ui-react'
import CampaignNegativeKeywordsTable from './campaignNegativeKeywordTable'
import PreviewTable from './previewTable'

const Preview = (props) => {
  const { combinationFactors, negativeKeywordFactors, leftCombinations, rightCombinations, roots, campaignNegativeKeywords, exportCsv, campaignInputRef } = props
  return <>
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} textAlign='center'>
          <Input placeholder='TYPE YOUR CAMPAIGN NAME' fluid className='preview-input' ref={campaignInputRef} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column width={16}>
          <PreviewTable
            leftCombinations={leftCombinations}
            rightCombinations={rightCombinations}
            roots={roots}
            combinationFactors={combinationFactors}
            // keyboardSculpting={negativeKeywordFactors.keyboardSculpting}
            negativeKeywordFactors={negativeKeywordFactors}
          />
        </Grid.Column>
        <Grid.Column width='16'>
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column width={10}>
          <CampaignNegativeKeywordsTable
            campaignNegativeKeywords={campaignNegativeKeywords}
            negativeKeywordFactors={negativeKeywordFactors}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment>
            <h4>Hey guys &#128578; We have put a lot of effort into building this free tool. The only thing that we ask in return is your feedback, so that we can make it better.</h4>
            <h4>Can you help us? We would be very grateful.</h4>
            <h4>Thanks! ❤</h4>
          </Segment>
          <div>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSfE4u6edNvzuJdvkNgDZPMy263Wls-LCUZ1XayBl5Ysk9Pcag/viewform' target='_blank' rel='noreferrer noopener'>
              <Button primary size='tiny' style={{ width: '60%' }}>Leave Feedback &#10084;&#65039;</Button>
            </a>
            <Button size='tiny' onClick={() => exportCsv()} style={{ width: '38%' }}>Download CSV</Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}
export default Preview
