import React, { useRef } from 'react'
import { Button, ButtonGroup, Checkbox, Divider, Grid, Icon, Input, Label, Segment } from 'semantic-ui-react'
import Combination from './combinations'
import '../../styles/styles.css'
import NegativeCampaignInfoPopup from './negativeCampaignInfoPopup'
import CombinationInfoPopup from './CombinationInfoPopup'

const CombinationSection = (props) => {
  const negativeKeywordFormCampaign = useRef(null)

  const { pushToCombinations,
    campaignNegativeKeywords = [],
    rightRef,
    leftRef,
    rootRef,
    roots,
    leftCombinations,
    rightCombinations,
    removeCombination,
    setShowPreivewModal,
    pushToNegativeCampaign,
    setCombinationFactorMethod,
    combinationFactors,
    removeFromNegativeCampaign,
    negativeKeywordFactors,
    setNegativeKeywordFactorsMethod,
    keywordCounts
  } = props

  return <>
    <Segment>

      <Grid columns={3} className='combination-wrapper'>
        <Grid.Row divided stretched >
          <Grid.Column>
            <Combination
              items={leftCombinations}
              title='Left'
              placeholder='Add left combinations'
              elementRef={leftRef}
              pushToCombinations={pushToCombinations}
              removeCombination={removeCombination}
              id='left'
            />
          </Grid.Column>
          <Grid.Column>
            <Combination
              items={roots}
              title='Roots / Ad Group'
              placeholder='Add roots / ad groups'
              elementRef={rootRef}
              pushToCombinations={pushToCombinations}
              removeCombination={removeCombination}
              id='root'
            />
          </Grid.Column>
          <Grid.Column>
            <Combination
              items={rightCombinations}
              title='Right'
              placeholder='Add right combinations'
              elementRef={rightRef}
              pushToCombinations={pushToCombinations}
              removeCombination={removeCombination}
              id='right'
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width='16'>
            <div className='checkbox-container'>
              <Checkbox checked={combinationFactors.dot1} onChange={() => setCombinationFactorMethod('dot1')} label={combinationFactors.dot1 ? <span className='checkbox checkbox-checked' /> : <span className='checkbox checkbox-unchecked' />} /> <img src='/images/dot_icon_1.png' alt='dot-icon-1' className='dot-label' />
              <Checkbox checked={combinationFactors.dot2} onChange={() => setCombinationFactorMethod('dot2')} label={combinationFactors.dot2 ? <span className='checkbox checkbox-checked' /> : <span className='checkbox checkbox-unchecked' />} /> <img src='/images/dot_icon_2.png' alt='dot-icon-1' className='dot-label' />
              <Checkbox checked={combinationFactors.dot3} onChange={() => setCombinationFactorMethod('dot3')} label={combinationFactors.dot3 ? <span className='checkbox checkbox-checked' /> : <span className='checkbox checkbox-unchecked' />} /> <img src='/images/dot_icon_3.png' alt='dot-icon-1' className='dot-label' />
              <Checkbox checked={combinationFactors.dot4} onChange={() => setCombinationFactorMethod('dot4')} label={combinationFactors.dot4 ? <span className='checkbox checkbox-checked' /> : <span className='checkbox checkbox-unchecked' />} /> <img src='/images/dot_icon_4.png' alt='dot-icon-1' className='dot-label' />
              <CombinationInfoPopup />
              {/* <Icon circular name='exclamation' size='small' /> */}
              <Button.Group size='tiny'>
                <Button
                  color={combinationFactors.broad ? 'blue' : ''}
                  onClick={() => setCombinationFactorMethod('broad')}
                  className='white-border-sides'
                >
                  Broad
                </Button>
                <Button
                  color={combinationFactors.quotes ? 'blue' : ''}
                  onClick={() => setCombinationFactorMethod('quotes')}
                  className='white-border-sides'
                >
                  "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                </Button>
                <Button
                  color={combinationFactors.brackets ? 'blue' : ''}
                  onClick={() => setCombinationFactorMethod('brackets')}
                  className='white-border-sides'
                >
                  [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
                </Button>
              </Button.Group>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width='16' textAlign='center'>
            <h4>Campaign Negative Keywords <Label circular>{campaignNegativeKeywords.length}</Label></h4>
          </Grid.Column>
          <Grid.Column width='16'>
            <div className='negative-keywords-wrapper'>
              <Divider />
              <Label.Group>
                {
                  campaignNegativeKeywords.map((nk, i) => (
                    <Label key={nk + i} style={{ backgroundColor: '#D1D9D9' }}>
                      {nk}
                      <Icon name='delete' onClick={() => removeFromNegativeCampaign(nk)} />
                    </Label>
                  ))
                }
              </Label.Group>
            </div>
          </Grid.Column>
          <Grid.Column width='16' textAlign='right' verticalAlign='middle'>
            <div className='negative-keyword-actions'>
              <form onSubmit={(e) => {
                e.preventDefault()
                const value = e.target.negativeKeyword.value
                pushToNegativeCampaign(value)
                negativeKeywordFormCampaign.current.inputRef.current.value = ''
              }}>
                <Input
                  type='text'
                  name='negativeKeyword'
                  size='mini'
                  placeholder='Add negative keyword'
                  icon={{
                    name: 'arrow circle up',
                    onClick: (e) => {
                      e.preventDefault()
                      const value = negativeKeywordFormCampaign.current.inputRef.current.value
                      pushToNegativeCampaign(value)
                      negativeKeywordFormCampaign.current.inputRef.current.value = ''
                    },
                    link: true,
                    color: 'blue',
                    size: 'large',
                  }}
                  ref={negativeKeywordFormCampaign}
                  focus={true}
                  style={{ border: 'none!important' }}
                  className='combination-input negative-input'
                />
              </form>

              <span className='negative-checkbox-container'>
                <Checkbox checked={negativeKeywordFactors.keyboardSculpting} onChange={() => setNegativeKeywordFactorsMethod('keyboardSculpting')} label={negativeKeywordFactors.keyboardSculpting ? <span className='checkbox checkbox-checked' /> : <span className='checkbox checkbox-unchecked' />} /> &nbsp;&nbsp;<span>Keyword sculpting</span>&nbsp;&nbsp;
                <NegativeCampaignInfoPopup />
                {/* <Icon circular name='exclamation' size='small' link /> */}
              </span>

              <ButtonGroup size='tiny' className='negative-keyword-button-group'>
                <Button
                  color={negativeKeywordFactors.broad ? 'blue' : ''}
                  onClick={() => setNegativeKeywordFactorsMethod('broad')}
                  className='white-border-sides'>
                  Broad
                </Button>
                <Button
                  color={negativeKeywordFactors.quotes ? 'blue' : ''}
                  onClick={() => setNegativeKeywordFactorsMethod('quotes')}
                  className='white-border-sides'
                >
                  "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                </Button>
                <Button
                  color={negativeKeywordFactors.brackets ? 'blue' : ''}
                  onClick={() => setNegativeKeywordFactorsMethod('brackets')}
                  className='white-border-sides'>
                  [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
                </Button>
              </ButtonGroup>
            </div>

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <div className='keyword-count'>
      <p>
        Your campaign will generate <Label color='blue' circular >{keywordCounts?.combinationCount ?? 0}</Label> keywords and <Label color='red' circular>{keywordCounts?.negativeKeywordCount ?? 0}</Label> negative keywords.
      </p>
      <Button primary onClick={() => setShowPreivewModal(true)}>Preview &amp; Export</Button>
    </div>
  </>
}

export default CombinationSection