import axios from "axios"
import { useCallback, useState, useEffect } from "react"
import { Icon } from "semantic-ui-react"

let tree = {}
const useSearchAndWheel = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState()
  const [searchCountry, setSearchCountry] = useState('United States')
  const [searchLanguage, setSearchLanguage] = useState('English')

  const [breadCrumbs, setBreadCrumbs] = useState([])
  const [current, setCurrent] = useState()
  const [languages, setLanguages] = useState()
  const [countries, setCountries] = useState()

  const maxNodesLimit = 15

  const setCurrentMethod = useCallback(() => {
    if (breadCrumbs.length && tree && JSON.stringify(tree) !== '{}') {
      const lastElement = breadCrumbs[breadCrumbs.length - 1]
      if (lastElement) {
        setCurrent({
          ...tree[lastElement.key],
        })
      }
    }
  }, [breadCrumbs])

  const getCountries = useCallback(async () => {
    try {
      const { data } = await axios.get('https://kmt-backend-re5ipdw63a-uc.a.run.app/api/v1/get_country_values')
      if (data.countries) {
        const options = data.countries.map((c, i) => ({ key: c + i, value: c, text: c }))
        setCountries([{ key: 'select', value: 'select', text: 'Country' }, ...options])
      }
    } catch (e) { console.error(e) }

  }, [])

  const getLanguages = useCallback(async () => {
    try {
      const { data } = await axios.get('https://kmt-backend-re5ipdw63a-uc.a.run.app/api/v1/get_language_values')
      if (data.languages) {
        const options = data.languages.map((c, i) => ({ key: c + i, value: c, text: c }))
        setLanguages([{ key: 'select', value: 'select', text: 'Language' }, ...options])
      }
    } catch (e) {
      console.error(e)
      alert('fetching languages error!')
    }
  }, [])

  useEffect(() => {
    getLanguages()
    getCountries()
  }, [getLanguages, getCountries])

  useEffect(() => {
    setCurrentMethod()
  }, [breadCrumbs, setCurrentMethod])


  const getGraphKeywordsMethod = () => {
    if (searchKeyword && searchKeyword.length >= 3) {
      setIsFetching(true)
      tree = {}
      getKeywordGraphApi()
        .then((res) => {
          setIsFetching(false)
          const data = JSON.parse(res.data)
          if (data.graph) {
            prepareData(data.graph.children, true, '/')
            setCurrentMethod()
          }
        })
        .catch(e => {
          setIsFetching(false)
          alert('something went wrong while searching')
          console.error(e)
        })
    }
  }

  const getKeywordGraphApi = () => {
    return axios.post(`https://kmt-backend-re5ipdw63a-uc.a.run.app/api/v1/get_keywords_graph`, {
      "keywords_as_string": searchKeyword,
      "language_as_string": searchLanguage,
      "location_as_string": searchCountry,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  const pushIntoBreadCrumbs = (element) => {
    if (element.key === '/') return setBreadCrumbs([element])
    setBreadCrumbs([
      ...breadCrumbs,
      element
    ])
  }

  const popFromBreadCrumbs = () => {
    setBreadCrumbs(s => [...s.breadCrumbs.pop()])
  }

  const prepareData = (data, isRoot = false, path, parent) => {
    const localTree = tree || {}
    // path = path.replace(/ /g, '_')
    let current = localTree[path] || {
      name: isRoot ? searchKeyword : data.name,
      path: isRoot ? '/' : path,
      frequency: data?.frequency ?? 0,
      parent,
      isRoot,
      view: false,
    }

    let sortedChildren

    if (isRoot) {
      const children = []
      sortedChildren = data.sort((a, b) => b.frequency - a.frequency).slice(0, 20)

      for (let i = 0; i <= maxNodesLimit; i++) {
        const p = sortedChildren[i]

        if (!p) break;

        children.push({
          name: p.name,
          path: path + p.name, //.replace(/ /g, '_'),
          totalChildren: p.children.length
        })
      }

      current['children'] = children

      pushIntoBreadCrumbs({
        key: '/',
        content: <span className="home-icon"><Icon name='home' className="home-icon" size='large' /><Icon name='right angle' style={{ fontSize: '1.2rem!important' }} /><span>{searchKeyword}</span></span>,
        link: false
      })
    } else {
      sortedChildren = data.children.sort((a, b) => b.frequency - a.frequency).slice(0, 20)
      const children = []
      for (let i = 0; i <= maxNodesLimit; i++) {
        const child = sortedChildren[i]
        if (!child) break;

        children.push({
          name: child.name,
          path: path + '/' + child.name, //.replace(/ /g, '_'),
          totalChildren: child.children.length
        })
      }
      current['children'] = children
    }

    tree[path] = current

    if (isRoot) {
      for (let i = 0; i <= maxNodesLimit; i++) {
        const p = sortedChildren[i]
        if (!p) break;

        prepareData(p, false, path + p.name, path)
      }
    } else {
      for (let i = 0; i <= maxNodesLimit; i++) {
        const child = sortedChildren[i]
        if (!child) break;

        prepareData(child, false, path + '/' + child.name, path)
      }
    }
  }

  const isViewed = (path) => {
    if (!tree)
      return
    if (!tree[path])
      return
    return tree[path].view
  }

  const changeCurrent = (path) => {
    if (!tree[path]) return
    const element = tree[path]

    element.view = true
    tree = {
      ...tree,
      [path]: element,
    }
    pushIntoBreadCrumbs({ key: element.path, content: element.name, link: false })
  }

  const onSearchDropdownChange = (value, type) => {
    try {
      switch (type) {
        case 'country':
          setSearchCountry(value)
          break
        case 'language':
          setSearchLanguage(value)
          break
        default:
          break
      }
    } catch (e) {
      console.error(e)
    }
  }

  const jumpToSection = (path) => {
    /**
     * get the node from tree
     * break the path and generate the bread crumbs
     * set the current node
     */
    const node = tree[path]
    if (!node) return
    const newBreads = [{
      key: '/',
      content: <span className="home-icon"><Icon name='home' className="home-icon" size='large' /><Icon name='right angle' style={{ fontSize: '1.2rem!important' }} />{searchKeyword}</span>,
      link: false
    }]

    if (path !== '/') {
      const pathArr = node.path.split('/')
      for (let i = 0; i < pathArr.length; i++) {
        const p = pathArr[i]
        if (p) {
          let path = []
          for (let j = 0; j <= i; j++) {
            path.push(pathArr[j])
          }
          if (path.length)
            newBreads.push({ key: path.join('/'), content: p, link: false })
        }
      }
    }
    setBreadCrumbs([...newBreads])
  }

  return {
    tree: tree,
    current,
    breadCrumbs,
    isFetching,
    languages,
    countries,
    maxNodesLimit,
    methods: {
      pushIntoBreadCrumbs,
      popFromBreadCrumbs,
      changeCurrent,
      jumpToSection,
      setSearchKeyword,
      isViewed,
      getGraphKeywordsMethod,
      onSearchDropdownChange,
    }
  }
}
export default useSearchAndWheel