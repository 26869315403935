import React from 'react'
import { Table } from 'semantic-ui-react'

const CampaignNegativeKeywordsTable = (props) => {
  const { campaignNegativeKeywords, negativeKeywordFactors } = props
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Campaign Negative Keyword</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <div className='preview-table-cell'>
              {
                campaignNegativeKeywords.map((cnk, i) => (
                  <div key={cnk + i}>
                    {
                      negativeKeywordFactors.quotes && <div>"{cnk}"</div>
                    }
                    {
                      negativeKeywordFactors.brackets && <div>[{cnk}]</div>
                    }
                    {
                      negativeKeywordFactors.broad && <div>{cnk}</div>
                    }
                  </div>
                ))
              }
            </div>
          </Table.Cell>
        </Table.Row>
      </Table.Body>

    </Table>
  )
}

export default CampaignNegativeKeywordsTable